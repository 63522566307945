import React, {useEffect, useState} from "react"
import Layout from "../components/layout"
import loginStyle from "./login.module.css"
import {Col, Row, Container, Button, Form, Alert} from "react-bootstrap";
import formStyle from "./form.module.css";
import globalStyle from "../styles/global.module.css";
import axios from "axios";
import env from "../config/env";

const useStateWithLocalStorageForEmail = localStorageKey => {
  //get email value from localStorage
  const [emailValueFromLocalStorage, setEmailValueFromLocalStorage] = useState(
    (typeof window !== "undefined" && localStorage.getItem(localStorageKey)) ||
    ""
  );

  //set user email value in localStorage
  useEffect(() => {
    localStorage.setItem(localStorageKey, emailValueFromLocalStorage)
  }, [emailValueFromLocalStorage])

  return [emailValueFromLocalStorage, setEmailValueFromLocalStorage]
};

const useStateWithLocalStorageForLast4 = localStorageKey => {
  const [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage] = useState(
    (typeof window !== "undefined" && localStorage.getItem(localStorageKey)) ||
    ""
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, last4ValueFromLocalStorage)
  }, [last4ValueFromLocalStorage]);

  return [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage]
}

const LoginPage = () => {

  const [emailValueFromLocalStorage, setEmailValueFromLocalStorage] = useStateWithLocalStorageForEmail("email");
  const [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage] = useStateWithLocalStorageForLast4("last4");

  const VIPMembersUrl = env.VIPMembersUrl;
  const [formState, setFormValues] = useState({ email: "", last4: "" });
  const [showLoginErrorMessage, setShowLoginErrorMessage] = useState({show: false, onField: false});
  const [showLoginSuccessMessage, setShowLoginSuccessMessage] = useState({show: false, message: ""});
  const [loggingIsBlocked, setLoggingIsBlocked] = useState(false);
  const [showMailchimpMessage, setShowMailchimpMessage] = useState({show: false, message:""});

  useEffect(() => {
    if (
      emailValueFromLocalStorage &&
      last4ValueFromLocalStorage &&
      typeof window !== "undefined"
    ) {
      window.location.replace("/member-lounge")
    }
  }, [emailValueFromLocalStorage, last4ValueFromLocalStorage]);

  //submit email form
  const handleLoginSubmit = async e => {
    e.preventDefault();

    try {
      const requiredFields = ["email", "last4"];
      for (const field in formState) {
        if (requiredFields.indexOf(field) !== -1) {
          if (!formState[field]) {
            setShowLoginErrorMessage({
              show: true,
              onField: field.charAt(0).toUpperCase() + field.slice(1),
            });
            return
          }
        }
      }

      if (loggingIsBlocked) {
        return
      }
      setLoggingIsBlocked(true);

      // check if an email and last4 combo exists in the VIPMember list
      const response = await axios({
        method: "get",
        url: VIPMembersUrl + formState.email + "/" + formState.last4,
        headers: {
          "Access-Control-Allow-Origin": window.location.origin,
        },
      });

      setLoggingIsBlocked(false);

      //if email address does not exist in VIP Members list then hide email form and show payment form
      if (response.data.memberExists === false) {
        setShowMailchimpMessage({
            show: true,
            message: "You are not a MOM member. Please"
          });
      } else {
        // if user email already exist in the list show the content
        setShowLoginSuccessMessage({
          show: true,
          message: "You are now logged in!"
        });
        setEmailValueFromLocalStorage(formState.email)
        setLast4ValueFromLocalStorage(formState.last4)
      }
    } catch (error) {
      console.log(error);
      setLoggingIsBlocked(false);
    }

  };

  const handleLoginFormChange = e => {
    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    });
    setShowLoginErrorMessage({show: false});
    setShowMailchimpMessage({show: false});
    setShowLoginSuccessMessage({show: false});
  };

  return (
    <Layout>
      <Container>
        <Row>
          <Col md={12} sm={12} xs={12}>
            <div>
              <h1 className={globalStyle.entryTitle}>Log in</h1>
            </div>

            <Row className={loginStyle.bannerContent}>
              <Col className={loginStyle.bannerColumn}>
                <Form onSubmit={handleLoginSubmit} name="signin" className={loginStyle.formContent}>
                  <Form.Row>
                    <Col xs={12} md={12} sm={12}>
                      <Form.Group as={Col} controlId="formEmail">
                        <Form.Control
                          size="sm"
                          value={formState.email}
                          name="email"
                          type="text"
                          placeholder="Please enter your email"
                          onChange={handleLoginFormChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12} sm={12}>
                      <Form.Group as={Col} controlId="formLast4">
                        <Form.Control
                          size="sm"
                          value={formState.last4}
                          name="last4"
                          type="text"
                          placeholder="Last 4 digits of your payment card"
                          onChange={handleLoginFormChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={12} sm={12}>
                      <Button
                        type="submit"
                        className={[formStyle.btnPrimary, formStyle.submitButtonOrange, loginStyle.submitButton]}>
                        Log in
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </Col>
            </Row>
            <Row>
              {
                showLoginErrorMessage.show &&
                <Col xs={12} md={12} sm={12}>
                  <Alert className={[loginStyle.alertBox, "mt-2"].join(" ")} variant="danger"
                         onClose={() => setShowLoginErrorMessage({show: false})}
                         dismissible
                  >{showLoginErrorMessage.onField} field is required!</Alert>
                </Col>
              }
              {
                showLoginSuccessMessage.show &&
                <Col xs={12} md={12} sm={12}>
                  <Alert className={[loginStyle.alertBox, "mt-2"].join(" ")} variant="success"
                         onClose={() => setShowLoginSuccessMessage({show: false})}
                         dismissible
                  >{showLoginSuccessMessage.message}</Alert>
                </Col>
              }
              {
                showMailchimpMessage.show &&
                <Col xs={12} md={12} sm={12}>
                  <Alert className={[loginStyle.alertBox, "mt-2"].join(" ")} variant="danger"
                         onClose={() => setShowMailchimpMessage({show: false})}
                         dismissible
                  >{showMailchimpMessage.message} <a className={loginStyle.joinUs} href='/become-a-member'>Join Us!</a></Alert>
                </Col>
              }
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
};

export default LoginPage
